@tailwind base;

@tailwind components;

@tailwind utilities;

.slick-track {
  margin: 0 auto !important;
  display: flex !important;
  justify-content: center !important;
  position: relative;
  z-index: 1;
}
.new-font {
  font-family: Inter, sans-serif !important;
}
.banner-head {
  font-family: "Playfair Display SC" !important;
}
